<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="orders" :actions="actions" />
        </div>
      </div>
    </div>
    <el-dialog
      title="Thông tin đơn hàng"
      :visible.sync="dialogDetail"
      width="70%"
      top="5vh"
    >
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Thông tin khách hàng</h6>
            </div>
            <div class="card-body">
              <p>Mã người dùng:
                <a class="pull-right" :href="`/#/users/${orderDetail.user_id}`" >{{orderDetail.user_id}}</a>
              </p>
              <p>Tên đầy đủ: <b class="pull-right">{{ orderDetail.name }}</b></p>
              <p>Tên: <b class="pull-right">{{ orderDetail.first_name }}</b></p>
              <p>Họ: <b class="pull-right">{{ orderDetail.last_name }}</b></p>
              <p>Email: <b class="pull-right">{{ orderDetail.email }}</b></p>
              <p>Số điện thoại: <b class="pull-right">{{ orderDetail.phone }}</b></p>
              <p>Giới tính: <b class="pull-right">{{ orderDetail.gender ? 'Nam' : 'Nữ' }}</b></p>
              <p>Ngày sinh: <b class="pull-right">{{ orderDetail.birthday }}</b></p>
              <p>Tỉnh/thành phố: <b class="pull-right">{{ orderDetail.province }}</b></p>
              <p>Quận/huyện: <b class="pull-right">{{ orderDetail.district }}</b></p>
              <p>Phường/xã: <b class="pull-right">{{ orderDetail.ward }}</b></p>
              <p>Địa chỉ: <b class="pull-right">{{ orderDetail.address }}</b></p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="card">
            <div class="card-header">
              <h6 class="title">Giỏ hàng</h6>
            </div>
            <div class="card-body">
              <p v-for="item of orderDetail.cart">
                {{ item.quantity + ' x ' + item.addon_title }} {{ item.variant_title != 'Default' ? ' - ' + item.variant_title : '' }} <b class="pull-right">{{$util.formatMoney(item.quantity * item.price)}}</b>
                <br>
                <span v-if="item.engraved_name">Khắc tên: <b>{{ item.engraved_name }}</b></span>
              </p>
              <hr>
              <p>Tạm tính: <b class="pull-right">{{$util.formatMoney(orderDetail.sub_total)}}</b></p>
              <p>Phí ship: <b class="pull-right">{{$util.formatMoney(orderDetail.shipping_fee)}}</b></p>
              <p>Phí khắc tên: <b class="pull-right">{{$util.formatMoney(orderDetail.engraved_name_price)}}</b></p>
              <p>Giảm giá: <b class="pull-right">{{$util.formatMoney(orderDetail.discount)}}</b></p>
              <p v-if="orderDetail.coupon_code">Mã giảm giá: <b class="pull-right">{{ orderDetail.coupon_code }}</b></p>
              <p>Tổng tiền: <b class="pull-right">{{$util.formatMoney(orderDetail.total_payment)}}</b></p>
              <hr>
              <p>Thời gian: <b class="pull-right">{{ orderDetail.created_at }}</b></p>
              <p>Trạng thái:
                <b v-if="orderDetail.status" class="badge badge-success pull-right">Đã thanh toán</b>
                <b v-else class="badge badge-danger pull-right">Chưa thanh toán</b>
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h6 class="title">Cổng thanh toán</h6>
            </div>
            <div class="card-body order-title">
              <div>
                <p>Cổng thanh toán: <b class="pull-right">{{ orderDetail.paygate_name }}</b></p>
                <p>Hình thức thanh toán: <b class="pull-right">{{ orderDetail.payment_name }}</b></p>
                <el-button class="pull-right" v-if="orderDetail.status == 0" type="success" @click="updatePaymentSuccess">Đã thanh toán</el-button>
                <div v-else>
                  <p v-if="orderDetail.payment_transaction_id">Mã giao dịch: <b class="pull-right">{{ orderDetail.payment_transaction_id }}</b></p>
                  <div v-if="orderDetail.payment_logs">
                    <p v-for="(value, key) in orderDetail.payment_logs"><span style="text-transform: capitalize;">{{ key }}</span>: <b class="pull-right">{{ value }}</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="Export"
      :visible.sync="dialogExportVisible"
      width="50%"
    >
      <div class="block text-center">
        <el-date-picker
          size='large'
          v-model="dateRange"
          type="daterange"
          start-placeholder="Từ ngày"
          end-placeholder="Đến ngày">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="dialogExportVisible = false">Đóng</el-button>
        <el-button size="sm" type="primary" @click="exportData">Export</el-button>
      </span>
    </el-dialog>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
  import { MessageBox, Message, Dialog, Button, DatePicker } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import orderSchemas from './order-schemas'
  import dtHelper from 'src/helpers/datatable'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default{
    components: {
      MyTable,
      MyFilter,
      Loading,
      ElButton: Button,
      ElDialog: Dialog,
      ElDatePicker: DatePicker
    },
    computed: {
      orders() {
        let rows = this.$store.state.orderProducts;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data() {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(orderSchemas),
        actions: [{
          type: 'primary',
          icon: 'el-icon-view',
          callback: this.detail
        }],
        filter: {},
        rules: dtHelper.buildRules(orderSchemas),
        dialogDetail: false,
        dialogExportVisible: false,
        orderDetail: {},
        dateRange: undefined,
        isLoading: false
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchOrderProducts');
      this.$store.dispatch('setPageTitle', 'Quản lý đơn hàng');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Export',
        type: 'primary',
        icon: '',
        callback: this.showModalExport
      }])
    },
    methods: {
      exportData() {
        if (!this.dateRange) {
          return Message({
            type: 'error',
            message: 'Chưa chọn khoảng thời gian'
          });
        }

        let from_date = this.dateRange[0];
        let to_date = this.dateRange[1];
        let data = {
          from: new Date(from_date).getTime() / 1000,
          to: new Date(to_date).getTime() / 1000
        };

        this.isLoading = true;
        this.$store.dispatch('exportOrderProduct', data)
        .then(res => {
          location.href = res.data;
          setTimeout(_ => {
            this.isLoading = false;
          }, 500);
        }, error => {
          Message({message: error, type: 'error'});
        });
      },

      showModalExport() {
        this.dialogExportVisible = true;
      },

      detail(index, row) {
        this.$store.dispatch('fetchOrderProductDetail', row.id)
        .then(res => {
          const order = res.data;
          this.orderDetail = Object.assign({}, order);
          if (this.orderDetail.payment_logs) {
            this.orderDetail.payment_logs = JSON.parse(this.orderDetail.payment_logs);
          }
          this.dialogDetail = true;
        }, error => {
          Message({message: error, type: 'error'});
        });
      },

      updatePaymentSuccess() {
        this.orderDetail.status = 1;
        this.$store.dispatch('updateOrderProduct', this.orderDetail).then(res => {
          Message({
            type: 'success',
            message: 'Cập nhật thành công'
          });
          let index = this.orders.findIndex(x => x.id == this.orderDetail.id);
          this.orders[index].status = 1;
        });
      },
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
